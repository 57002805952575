@import '../breakpoints.scss';

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    
    z-index: 1;
  }

  .clouds {
    position: absolute;
    
    width: 100%;
    height: 60%;

    background-size: 100% 100%;
    background-position: top;
    background-repeat: no-repeat;

    z-index: 3;
  }

  .sun_rays {
    position: absolute;
    
    width: 100%;
    height: 100%;

    background-size: auto;
    background-position: center top;
    background-repeat: no-repeat;

    z-index: 2;
  }

  .foreground {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;

    z-index: 10;
  }

  .bakery {
    max-height: 85%;
    max-width: 65%;
  }

  .plants {
    max-height: 15%;
    max-width: 10%;
  }

  .scooter {
    max-height: 35%;
    max-width: 20%;
  }

  @media (orientation: portrait) {
    height: 65vh;

    .foreground {
      flex-direction: row-reverse;
    }

    .plants {
      display: none;
    }

    .scooter {
      max-width: 25%;
      transform: scaleX(-1);
    }

    .bakery {
      margin-left: -14%;
      max-width: 80%;
    }
  }
}


