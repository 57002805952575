.fileUpload {
  margin-top: var(--field-margin, 40px);

  label {
    border: 2px solid #78AC42;
    border-radius: 50ch;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    margin-right: 8px;
    padding: 5px 12px;
    color: #78AC42;
  }

  .filename {
    color: #78AC42;
    font-size: .875em;
  }

  input {
    display: none;
  }

  p {
    color: #F6F4D4;
  }
}