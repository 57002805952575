@import '../colors.module.scss';

.eventDisplay {
  background: #08151B;
  border: 9px solid #C76931;

  position: absolute;
  height: 60%;
  top: 10vh;
  right: 7vh;
  left: 200px;

  @media (max-width: 912px) {
    left: 7vh;
    top: 90px;
    bottom: 10vh;
  }

  @media (max-width: 550px) {
    font-size: .8em;
    left: 4vh;
    right: 4vh;
    bottom: 5vh;
    height: auto;
  }

  .buttonContainer {
    @media (max-width: 912px) {
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      display: flex;
      top: 10px;
      left: 0;
    }

    position: relative;
    top: 31px;
    left: 34px;

    >button:first-child {
      margin-right: 30px;
    }

    .selectedButton {

      font-family: Montserrat;
      font-style: normal;
      text-align: center;
      border: 2px solid #F6F4D2;
      border-radius: 5px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      width: 93px;
      height: 38px;
      background: #F6F4D2;
      color: #08151B;
      cursor: pointer;
    }
  
    .unselectedButton {

      font-family: Montserrat;
      font-style: normal;
      text-align: center;
      border: 2px solid #F6F4D2;
      border-radius: 5px;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      width: 93px;
      height: 38px;
      color: #F6F4D2;
      background: transparent;
      cursor: pointer;
    }
  }

  p.dayHeading {
    position: absolute;
    color: $background-color;
    padding: 20px 20px 0 20px;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    left: 320px;
    top: 15px;

    @media (max-width: 912px) {
      display: none;
    }

    @media (max-width: 1300px) {
      font-size: 20px;
    }

    .timezoneText {
      font-weight: 300;
      margin-left: 7px;
    }
  }

  .container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;

    @media (max-width: 912px) {
      position: absolute;
      top: 60px;
    }
  }

  .events {
    position: absolute;
    background-color: rgba(246, 244, 212, 0.92);
    border-radius: 10px;
    top: 3vh;
    bottom: 3vh;
    right: 3vh;
    left: 320px;
    
    overflow: scroll;
    overflow-x: hidden;
    // overflow-y: hidden;

    @media (max-width: 912px) {
      top: 150px;
      bottom: 2vh;
      right: 2vh;
      left: 2vh;
    }

    .eventWrapper {
      margin-bottom: 10px;
      display: flex;
      overflow: auto;

      .times {
        box-sizing: border-box;
        padding-right: 20px;
        width: 150px;
        text-align: right;

        @media (max-width: 950px) {
          width: 120px;
        }

        @media (max-width: 550px) {
          width: 75px;
          padding-right: 10px;
        }

        h1 {
          font-size: 1.25em;
          font-weight: 500;
          margin-bottom: 5px;

          @media (max-width: 550px) {
            font-size: 1.05em;
          }
        }

        h3 {
          font-size: .9375em;
          opacity: 0.5;
          font-weight: 500;
          margin: 0;
        }
      }

      .body {
        flex: 1;
        position: relative;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        padding: 2px;
        width: 45vw;
        height: fit-content;
        display: flex;

        .lineContainer {
          margin-left: 10px;
          width: 20px;
          text-align: center;

          @media (max-width: 550px) {
            margin-left: 3px;
          }

          .line {
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            display: inline-block;
            width: 7px;
            height: calc(100% - 40px);
            background: #ccc; // this color should never actually be used (one of the following event type styles should always apply)
            border-radius: 7px;
          }

          .line[data-type='other'] { background: $other-event-color; }
          .line[data-type='minievent'] { background: $minievent-color; }
          .line[data-type='qna'] { background: $qna-color; }
          .line[data-type='workshop'] { background: $workshop-color; }

          .megaphone {
            position: relative;
            top: 20px;
            @media (max-width: 550px) {
              top: 15px;
            }
          }
        }

        .text {
          flex: 1;
          padding: 0 30px 0 15px;
          line-height: 1.5em;
          @media (max-width: 550px) {
            padding: 0 10px 0 10px;
          }


          h2 {
            font-weight: 600;
            font-size: 1.125em;
            margin-bottom: 0;
          }

          h2.announcement {
            margin: 1.0em 0;
          }

          h3 {
            font-weight: 400;
            font-style: italic;
            margin: 0 auto;
          }

          p {
            font-size: 1em;
            white-space: pre-line;
          }

          a {
            color: inherit;
            word-break: break-all;
          }

          .points {
            border-radius: 20px;
            display: inline-block;
            padding: 1px 10px;
            margin-top: 5px;
            margin-left: -2px;
            font-weight: 500;
            &[data-type='other'] { background: $other-event-color; color: black; }
            &[data-type='minievent'] { background: $minievent-color; }
            &[data-type='qna'] { background: $qna-color; }
            &[data-type='workshop'] { background: $workshop-color; }
          }
        }
      }
    }
  }
}