.ovenLights {
  position: absolute;
  top: 92px;
  display: flex;
  flex-direction: row;
  z-index: 5;
  align-content: center;
  width: 70vw;
  max-width: 80vw;
  min-width: 1075px;
  align-self: center;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-height: 850px) {
    top: 62px;
    left: 42vw;
    transform: scale(0.8) translateX(-50%);
    width: 75vw;
    margin: auto;
  }

  .lightGroup {
    text-align: center;
    margin: auto;
    .image {
      width: 68px;
      margin: auto;
      justify-content: center;
      align-items: center;
    }
    margin: auto;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
  }


  p {
    text-align: center;
    margin-top: 5px;
    font-size: medium;
    width: 148px;
    // height: 25px;
    
  }


  .text {
    --size: 30px;
    font-size: var(--size);
    font-family: Montserrat, sans-serif;
    transform: translateX(calc((16px - var(--size)) * var(--length) * .3));


    @media (max-width: 1000px) {
      --size: 17.5px;
    }
    @media (max-width: 900px) {
      --size: 19px;
    }


    @media (min-width: 1100px) {
      --size: 15px;
    }
    @media (min-width: 1200px) {
      --size: 14px;
    }
    @media (min-width: 1300px) {
      --size: 13px;
    }
    @media (min-width: 1400px) {
      --size: 12px;
    }
  }
}

// @import 'common.scss';

// .navigation {
//   position: relative;
//   width: 100%;
//   overflow: visible;
//   height: 130px;
//   z-index: 15;
  
//   @media (max-height: 700px) {
//     height: 115px;
//   }

//   @media (min-height: 800px) {
//     height: 150px;
//   }

//   @media (max-width: 800px) {
//     visibility: hidden;
//   }

//   @include registration-floating-screen { // the point at which the legs of the screen are not visible anymore 
//     display: none;
//   }

//   .fixtures {
//     width: 300vw;
//     height: auto;
//     position: absolute;
//     max-width: 4800px;
//     left: 50%;
//     transform: translateX(-50%);
//     z-index: 15;
//     overflow: visible;

//     @media (max-height: 700px) {
//       max-width: 4200px;
//     }

//     .lightGroup {
//       cursor: pointer;
//       &.disabled {
//         cursor: default;
//       }
//     }

//     .text {
//       --size: 16px;
//       font-size: var(--size);
//       font-family: Montserrat, sans-serif;
//       transform: translateX(calc((16px - var(--size)) * var(--length) * .3));


//       @media (max-width: 1000px) {
//         --size: 17.5px;
//       }
//       @media (max-width: 900px) {
//         --size: 19px;
//       }


//       @media (min-width: 1100px) {
//         --size: 15px;
//       }
//       @media (min-width: 1200px) {
//         --size: 14px;
//       }
//       @media (min-width: 1300px) {
//         --size: 13px;
//       }
//       @media (min-width: 1400px) {
//         --size: 12px;
//       }
//     }
//   }
// }
