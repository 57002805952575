.background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  

  .kitchen {
    width: 100%;
    // height: calc(100% - 28vw); // ensure that the stars don't overlap with the translucent mountains
    height: 100vh;
    // max-height: 425px;
    // background-position: center top;
    // background-size: 1500px;
    background-position: center;
    background-size: cover;
  }

  .mountains {
    position: absolute;
    bottom: 0;
    width: 115%;
    left: 53%;
    transform: translateX(-50%);

    @media (min-width: 2000px) {
      width: 100%;
      left: 50%;
    }
  }

  .road {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 6vw;
    background-color: #282939;

    @media (min-width: 2000px) {
      height: 5.5vw;
    }
  }
}