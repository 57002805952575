.input {
  display: block;
  font-family: inherit;
  border: none;
  outline: none;
  font-size: 1.25em;
  font-weight: 500;
  border-bottom: 1.5px solid #F6F4D4;
  width: 100%;
  padding: 5px 0;
  margin-top: var(--field-margin, 40px);
  background-color: transparent;
  color: #F6F4D4 !important;

  &::placeholder {
    color: #F6F4D4;
    font-size: 0.9em;
  }

  &.multiline {
    font-size: 1.1em;
  }
}