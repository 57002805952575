.button {
  background-color: #F19B79;
  ;
  color: white;
  padding: 6px 14px;
  font-weight: 600;
  font-size: 1em;
  font-family: inherit;
  border: none;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;

  .arrowIcon {
    width: 7px;
    
    &.right {
      transform: scale(1.1);
      &.margin {
        margin-left: 10px;
      }
    }

    &.left {
      transform: rotate(180deg) scale(1.1);
      &.margin {
        margin-right: 10px;
      }
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: #6D7072;
  }

  &.loading {
    cursor: not-allowed;
  }
}