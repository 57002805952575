.background {
  @media (max-width: 912px) {
    filter: blur(4px);
  }

  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #08151B;
  display: flex;
  margin: 0;

  .logo { // Taken from homepage
    @media only screen and (max-width: 912px) {
      top: 38px;
      left: 0;
      right: 0;
      margin: auto;
    }

    position: absolute;
    top: 20px;
    left: 20px;

    width: 10vw;
    height: 4vw;
    min-width: 128px;
    min-height: 42px;

    background-size: 100% 100%;
    background-repeat: no-repeat;

    z-index: 25;
  }

  .wall {
    @media only screen and (max-width: 912px) {
      height: 55%;
      width: 100%;
    }

    height: 75%;
    width: 100%;
    position: absolute;
    top: 0;
    background: #E6988E;
  }

  .floor_separator {
    @media only screen and (max-width: 912px) {
      height: 2%;
      width: 100%;
      top: 53%;
    }

    height: 3%;
    width: 100%;
    position: absolute;
    top: 75%;
    background: #E8D7A8;
  }

  .display_half {
    @media only screen and (max-width: 912px) {
        display: none;
    }

    max-height: 25.5%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .display_full {
    @media only screen and (max-width: 912px) {
      bottom: 27%; 
      max-height: 20%;
      opacity: 1;
    }

    opacity: 0;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 5vh;
  }

  .shelf {
    @media only screen and (max-width: 912px) {
      display: none;
    }

    position: absolute;
    max-height: 35%;
    left: 0;
    top: 25vh;
  }
}
