.discover {
    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        background-color: #08151B;
        display: flex;
        margin: 0;

        .logo { // Taken from homepage
            @media only screen and (max-width: 912px) {
                top: 38px;
                left: 0;
                right: 0;
                margin: auto;
            }

            position: absolute;
            top: 20px;
            left: 20px;
        
            width: 10vw;
            height: 4vw;
            min-width: 128px;
            min-height: 42px;
        
            background-size: 100% 100%;
            background-repeat: no-repeat;
        
            z-index: 25;
        }

        .wall {
            height: 55%;
            width: 100%;
            position: absolute;
            top: 0;
            background: #E6988E;
        }
        
        .floor_separator {
            height: 2%;
            width: 100%;
            position: absolute;
            top: 53%;
            background: #E8D7A8;
        }

        .shelf {
            @media only screen and (max-width: 912px) {
                display: none;
            }

            position: absolute;
            max-height: 20%;
            left: 5vh;
            top: 20vh;
        }

        .display {
            @media only screen and (max-width: 912px) {
                bottom: 27%; 
                max-height: 20%;
            }

            max-height: 42%;
            position: absolute;
            z-index: 2;
            right: 0;
            bottom: 5vh;
        }

        .blackboard {
            @media only screen and (max-width: 912px) {
                top: 20%;
                left: 0;
                right: 0;
                bottom: 60%;
                margin: auto;
                max-width: 85%;
                max-height: 85%;
            }

            max-height: 45%;
            right: 3vh;
            top: 7vh;
            position: absolute;
        }

        .blackboard_hover {
            @media only screen and (max-width: 912px) {
                top: 20%;
                left: 0;
                right: 0;
                bottom: 60%;
                margin: auto;
                max-width: 85%;
                max-height: 85%;
            }

            max-height: 45%;
            right: 3vh;
            top: 7vh;
            position: absolute;
            opacity: 0;
        }

        .blackboard_hover:hover {
            opacity: 1;
        }
    }
}