.navBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .logo {
    margin: 20px;
    width: 10vw;
    height: 4vw;
    min-width: 128px;
    min-height: 42px;

    z-index: 25;

    &.hidden {
      visibility: hidden;
    }
  }

  .link {
    margin: 0;
    text-align: right;

    &.mobile {
      padding: 5vh 0;
      text-align: center;
    }
  }

  .registerButton {
    position: absolute;
    box-sizing: border-box;
    top: 25px;
    right: 80px;
    height: 40px;
    padding: 0.5em 30px;

    @media (max-width: 480px) {
      top: 90px;
      left: 0;
      right: 0;
      margin: 0 auto;

      font-size: 18px;
      width: 120px;
      padding: 10px 20px;
    }

    color: black;
    font-weight: 600;
    font-size: 20px;
    text-decoration: none;
    line-height: 18px;
    
    border: 2px solid #08151B;
    border-radius: 15% / 50%;

    z-index: 25;
  }
}

.sideBar { 
  position: absolute;
  top: 20px;
  right: 20px;

  border-radius: 20px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.open {
    background-color: #FFFEE6;
  }

  &.mobile.open {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    border-radius: 0;
    padding: 20px;
  }
}

.sideBarLinks {
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
}

.sideBarToggle {
  padding: 5px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 100;

  .menuIcon {
    width: 40px;
    height: auto;
    margin-right: 0px;
  }

  .closeIcon {
    font-size: 50px;
    font-weight: 300;
    line-height: 30px;
    margin-right: 5px;
  }
}

