.schedule {
  width: 100%;
  overflow-x: hidden;

  .flexRow {
    display: flex;

    @media (max-width: 950px) {
      margin-top: 20px;
      flex-direction: column;
    }

    .left {
      flex: 1;

      display: flex;
      flex-direction: column;
      padding-top: 100px;
      padding-left: 60px;

      @media (max-width: 950px) {
        padding: 0;
        align-items: center;
        margin-top: 60px;
      }
    }

    .right {
      flex: 5;
      padding-top: 80px;
      padding-right: 80px;

      @media (max-width: 950px) {
        padding-top: 20px;
        padding-right: 20px;
      }

      @media (max-width: 550px) {
        padding-right: 15px;
      }
    }
  }
}