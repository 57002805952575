.checkboxLabel {
  display: block;
  position: relative;

  padding-left: 1.95em;
  margin: var(--checkbox-margin, 25px) 0;
  
  font-size: 1.25em;
  z-index: 1;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    
    width: 1.25em;
    height: 1.25em;
    
    border: 2px solid #C9CF76;
    border-radius: .5em;
  }

  &:active::before, &.checked::before {
    background-color: #C9CF76;
  }
  
  .checkbox {
    position: absolute;
    left: -9999px;
  }
}