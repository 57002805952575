.footer {
    background-color: #F6F4D4;
    border: 8px solid black;
    padding: 12px;
}

.innerFooter {
    border: 4px solid black;
    padding: 50px 0;
    text-align: center;

    img {
        display: inline-block;
    }
}

.socialsWrapper {
    margin-top: 64px;
    display: inline-block;
    text-align: center;
}

.socials {
    display: grid;
    grid-template-columns: repeat(6, 64px);
    column-gap: 64px;

    @media (max-width: 900px) {
        grid-template-columns: repeat(3, 64px);
        column-gap: 32px;
        row-gap: 32px;
    }

    .social {
        background-color: #F29B78;
        height: 64px;
        width: 64px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-of-type) {
            margin-right: 64px;
        }

        img {
            width: 30px;
        }
    }
}
