@import 'common.scss';

.rsvp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;

  height: 100%;
  min-height: 650px;
  position: relative;

  @include registration-floating-screen {
    justify-content: center;
    padding: 30px 0;
    box-sizing: border-box;
    min-height: 430px;
  }
}
