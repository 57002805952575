@import '../colors.module.scss';

.calendar {
  border: 4px solid $background-color;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  user-select: none;
  width: fit-content;
  padding: 0 10px;

  position: absolute;
  left: 5vh;
  top: 5vh;

  @media (max-width: 912px) {
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
  }

  p {
    padding-top: 10px;
    margin: auto;
    font-weight: 600;
    font-size: 24px;
    color: $background-color;
  }

  table {
    border-collapse: separate;
    border-spacing: 1px 10px;

    .calendarDay {
      color: $background-color;
      opacity: 0.6;
      font-size: 18px;
      border-radius: 50%;
  
      .dayButton {
        display: block;
        box-sizing: content-box;
        width: 20px;
        height: 20px;
        padding: 4px;
        text-align: center;
        background: none;
        border: none;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        cursor: inherit;
      }
    }
  
    .calendarDay[data-active='yes']:hover {
      background-color: darken($other-event-color, 20%);
    }
  
    .selectedDay {
      opacity: 1;
      background-color: $other-event-color;
    }
  
    .activeDay {
      opacity: 1;
      cursor: pointer;
    }

    .activeDay_disabled {
      opacity: 1;
    }
  }
}